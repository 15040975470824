import { ui } from '@nerdgeschoss/shimmer';

export async function enableGoogleBadge(): Promise<void> {
  await ui.consent.consentFor('targeting');

  const scriptTag = document.createElement('script');
  scriptTag.src = 'https://apis.google.com/js/platform.js?onload=renderBadge';
  scriptTag.async = true;
  scriptTag.defer = true;

  document.head.appendChild(scriptTag);
}

import '@hotwired/turbo-rails';
import { start } from '@nerdgeschoss/shimmer';
import { application } from '/controllers/application';
import { registerControllers } from 'stimulus-vite-helpers';
import { startErrorTracking } from '/lib/error-tracking';
import { enableAnalytics } from '/model/enableAnalytics';
import { enableSendInBlue } from '/model/enableSendInBlue';
import { enableHotJar } from '/model/enableHotJar';
import { enableGoogleBadge } from '/model/enableGoogleBadge';

// Controller files must be named *_controller.ts/js.
const controllers = import.meta.glob('../**/*_controller.{ts,js}', {
  eager: true,
});
registerControllers(application, controllers);

startErrorTracking();

start({ application });

enableAnalytics();
enableSendInBlue();
enableHotJar();
enableGoogleBadge();
